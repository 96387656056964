<template>
     <s-relatorio>

        <div class="d-flex justify-content-center" v-if="!tarefa">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <section id="tarefa" v-if="tarefa">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="margin-t1">{{ tarefa.titulo }}</h1>
               
                    <span class="badge bg-secondary" :style="'position: relative; background-color: ' + $projeto.status_cor(tarefa.status) + '!important;'">
                            {{ tarefa.status.nome | upper }}
                            <span v-if="tarefa.prioridade && tarefa.prioridade.nome" class="position-absolute top-0 start-100 translate-middle p-1 border border-light rounded-circle" :title="tarefa.prioridade.nome" :style="{ 'background-color' : tarefa.prioridade.cor }">
                                <span class="visually-hidden">{{ tarefa.prioridade.nome }}</span>
                            </span>
                    </span>

                    <br v-if="tarefa.tags" />

                    <span v-for="(tag, tag_i) in tarefa.tags" :key="tag_i" class="tags" :style="'color: ' + tag.cor + ';'">
                        <i class="bi bi-tag-fill"></i> {{ tag.nome }}
                    </span>
                    
                    <!--
                    <div id="tempo">
                        <strong>Tempo Gasto:</strong> ⏱ 99
                    </div>
                    -->
                    
                    <div id="tempo" v-if="tarefa.vencimento" :title="$moment(tarefa.vencimento).format('dddd, LL')">
                        <strong>Previsão de Entrega:</strong> 🗓️ {{ tarefa.vencimento + ' 23:59:00' | moment("from", "now")  }}
                    </div>
                    
                </div>

        
            </div>
           
            <div class="row">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>

            <div class="notion-bloco">

                <div class="alert alert-info" role="alert" v-if="!tarefa.briefing">
                    Essa tarefa ainda não teve um briefing informado.
                </div>
                <span style="display: none">{{ tarefa.briefing  }}</span>
                <div>
                    <h1>Briefing</h1>
                    <markdown>{{ tarefa.briefing  }}</markdown>
                </div>
                

            </div>
            
            <div class="row">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>

            <h1 v-if="tarefa.feedbacks.length > 0">Feedbacks</h1>
            <div v-if="tarefa.feedbacks.length === 0" class="alert alert-warning" role="alert">Nenhum feedback enviado até o momento. </div>

            <div class="row padding-md-t1">
                <div class="col-md-12 notion-comentarios">

                    <div v-for="(feedback, feedback_i) in tarefa.feedbacks" :key="feedback_i">
                        <div v-show="feedback.feedback" class="alert alert-primary" role="alert" style="margin-top: 25px; margin-bottom: 5px;">
                            <markdown>{{ feedback.feedback }}</markdown>
                            <br><br>
                            <p :title="$moment(feedback.data).format('dddd, LL')">
                                <small><strong>{{ feedback.autor }}</strong> - {{ feedback.data | moment("from", "now") }}</small>
                            </p>
                        </div>

                        <div v-for="(arquivo, arquivo_i) in feedback.arquivos" :key="arquivo_i" class="alert alert-warning" role="alert" style="padding: 5px 15px; font-size: 13px; margin-bottom: 5px;">
                            <i class="bi bi-paperclip"></i> 
                            <a :download="arquivo.nome" :href="arquivo.url" target="_blank">{{ arquivo.nome }}</a>
                        </div>

                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-12">
                    <span class="dados margin-t1">
                        <code><strong>ID: {{ tarefa.id }}</strong></code>
                    </span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <span class="dados">
                        <router-link :to="{ name: 'projeto', params: { id: $route.params.projeto_id } }">Ver todas as tarefas desse projeto.</router-link>
                    </span>
                </div>
            </div>

        </section>

    </s-relatorio>
</template>

<script>

import moment from 'moment';

export default {
    metaInfo () {
        return {
            title: (this.tarefa) ? this.tarefa.titulo : 'Carregando...'
        }
    },
    data () {
        return {
            tarefa: null,
            status: 'pendente'      
        }
    },

    computed: {

        momento(){
            return moment();
        },

        cliente() {
            return this.$store.state.cliente.dados;
        },

        loading() {
            return (!this.tarefa);
        }

    },

    mounted: function(){

        this.load();

    },

    methods: {

        async load(){

            try{

                /** TAREFAS */
                console.log('[TAREFA ID]', this.$route.params.id);
                let tarefas = await this.$sdk.get('/tarefas/' + this.$route.params.projeto_id + '/' + this.$route.params.id);
                this.tarefas_loading = false;
                console.log('[TAREFA]', tarefas);
                this.tarefa = tarefas[0];

            }
            catch(e){
                this.$swal.fire({
                title: 'Estamos com dificuldade de acessar essa informação.',
                text: 'Tente novamente em instantes.',
                icon: 'error',
                confirmButtonText: 'Tentar de novo',
                }).then((result) => {
                    if (result.isConfirmed) {
                        document.location.reload();
                    }
                })

            }

        }

    }
}

</script>