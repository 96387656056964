import home from './pages/home';
import projeto from './pages/projeto';
import tarefa from './pages/tarefa';

const routes = [
    {
        name: 'home',
        path: '/',
        component: home
    },
    {
        name: 'projeto',
        path: '/p/:projeto_id',
        component: projeto
    },
    {
        name: 'tarefa',
        path: '/t/:projeto_id/:id',
        component: tarefa
    },

  ]

export default routes