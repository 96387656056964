<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'App',   
    metaInfo: {
      title: 'Carregando...',
      titleTemplate: '%s | Dash Rocket',
      meta: [{
            name: 'robots',
            content: 'noindex, nofollow',
        }]
    },

    data: function(){
        return {
            scripts_carregados: false,
        }
    },

    mounted: function() {
        this.cliente_load(this.$route);
    },

    methods: {
        ...mapActions('cliente', {
            cliente_load: 'load'
        }),
    },

    computed: {
        projeto(){
            return this.$store.state.cliente.projeto;
        }
    },

    watch: {
        projeto(reg){

            if(!this.scripts_carregados){

                this.scripts_carregados = true;

                //Tawk
                if(reg.tawk_id){

                    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                    console.log('[Tawk]', Tawk_LoadStart);
                    
                    (function(){
                    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src='https://embed.tawk.to/' + reg.tawk_id;
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                    })();

                }

                console.log('[Rocket GO]', reg.rocket_go);
                
            }
            
        }
    }

}
</script>

<style lang="scss">
    @import "@sass/index.scss";
</style>