import sdk from "../sdk"

export default {
    namespaced: true,

    state: {
        dados: null,
        tarefas_qtd: 0,
        projeto: null
    },

    mutations: {

        RENDER(state, data) {

            //atualiza states com dados carregados da API
            state.dados = data.dados;
            state.projeto = data.projeto;
            state.tarefas_qtd = data.tarefas_qtd;

        }

    },

    actions: {

        async load({ commit }, $route) {

            let cliente = await sdk.get('/cliente');
            //console.log('CLIENTE CARREGADO', cliente);

            let projeto = await sdk.get('/projeto/' + $route.params.projeto_id);

            let data = {
                dados: cliente,
                projeto: projeto,
                tarefas_qtd: 99,
            };

            commit('RENDER', data);

        }

    }

};