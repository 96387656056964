<template>
    <section id="relatorio">

        <div class="d-flex justify-content-center padding-5"  v-if="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <section class="fatura margin-md-y2" v-if="!loading">
            <div class="container pagina padding-1 padding-md-2 padding" >
        
                <div class="row margin-b1">
        
                    <div class="order-2 order-md-1 col-8 offset-2 offset-md-0 col-md-8">
                        <h1 v-if="cliente">{{ cliente.nomefantasia }}</h1>
                        <span class="dados" v-if="cliente">{{ cliente.razaosocial }}</span>
                        <span class="dados" v-if="cliente">{{ cliente.cnpj }}</span>
                        <span class="dados" v-if="cliente"><nl2br>{{ cliente.endereco }}</nl2br></span>
                    </div>

                    <div class="order-1 order-md-2 col-8 offset-2 offset-md-0 col-md-4 margin-b1 margin-sm-b0">
                        <img :src="cliente.logo" class="logo" />
                    </div>

                </div>

                <slot></slot>
            </div>
            

            

            <div class="container footer padding-y1">
              <div class="row">
                    <div class="col-md-12 text-center">
                        Está é uma página privada com todos os direitos à <strong> {{ cliente.razaosocial }} ({{ cliente.cnpj }})</strong>. 
                        Não compartilhe essa URL com ninguém sem que você tenha de fato autorização.
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 margin-y1 text-center">
                        Feito com ❤️ pela <a href="http://www.produtorarocket.com" target="_blank" title="Rocket Produtora Digital de Camboriú, SC.">Roket Produtora Digital</a>.
                    </div>
                </div>
            </div>
       

        </section>

    </section>

</template>
<script>

export default {
    data () {
        return {
            
        }
    },

    computed: {

        cliente() {
            return this.$store.state.cliente.dados;
        },

        loading() {
            return (!this.$store.state.cliente.dados);
        }

    }




}

</script>