import store from './stores';
export default {
    config: '',

    status_cor(status){

        let $config;

        if(store.state.cliente.projeto && store.state.cliente.projeto.json){
            $config = JSON.parse(store.state.cliente.projeto.json);
        } else {
            $config = {};
        }

        
        if($config.status && $config.status.cores){

            for  (let sts of $config.status.cores){
        
                if(status.nome.toLowerCase() == sts.nome.toLowerCase()){
                    return sts.cor
                }
            }

        }
        
        return status.cor;

    },

   
    /**
     * Instalar use no Vue.
     * 
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$projeto = this;
        Vue.projeto = this;

    }
};
