import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

//Importa rotas
import routes from "./routes"
const router = new Router({ 
    mode: 'history',
    base: '',
    routes
})

router.beforeEach((to, from, next) => {
    
    /** ROCKET GO! */
    console.log('[router go - router]', window.rocket_go);

    var url = 'https://go.produtorarocket.com/';
    url += '?notify=all';
    url += '&url=' + encodeURI(document.location.protocol + '//' + document.location.host + to.fullPath);
    url += '&src=' + encodeURI(document.title);
    url += '&type=pixel';

   if (!localStorage.getItem('rocket-go')) {

        console.log('Rocket Go! disparado...');
        localStorage.setItem('rocket-go', new Date());
        document.location.href = url;

    } else {
        console.log('Rocket Go! concluído.');
        localStorage.removeItem('rocket-go');
        
        next();
    }

})


export default router