import Vue          from 'vue'
import axios        from 'axios'
import VueAxios     from 'vue-axios'

Vue.use(VueAxios, axios)

export default {

    uri : process.env.VUE_APP_API_URI,

    request(method, endpoint, data){

        return new Promise( (resolve, reject) => {
            
            axios({
                method: method,
                
                url: this.uri +  endpoint,
                headers: {
                    'dash_id' : process.env.VUE_APP_PROJETO_ID
                },
                data: data
            })
            .then(ret => {
                resolve(ret.data);
            })
            .catch(erro =>{           
                reject(erro);
            })

        })

    },

    async get(endpoint, data){

        console.log('[API GET]', endpoint);
        return await this.request('get', endpoint, data);

    },

   
    /**
     * Instalar use no Vue.
     * 
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$sdk = this;
        Vue.sdk = this;
    }
};