<template>
    <div>
        <s-relatorio v-if="cliente">
            
            <div class="row">
                <div class="col-md-12">
                    <h1 class="margin-t1">{{ this.$store.state.cliente.projeto.nome }}</h1>

                    <div id="tempo">
                        <strong>Tarefas não concluídas: </strong> 
                        <span  v-if="!tarefas_loading">{{ tarefas_qtd }}</span>
                        <div v-if="tarefas_loading" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>

            <div class="d-flex justify-content-center padding-5"  v-if="tarefas_loading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="alert alert-warning" role="alert" v-if="tarefas_qtd == 0 && !tarefas_loading">Nesse momento, não existe nenhuma tarefa pendente para este cliente.</div>

            <div class="alert alert-primary tarefa-resumo" role="alert" v-for="(tarefa, tarefa_i) in tarefas" :key="tarefa_i" >
                <div class="row">
                    <div class="col-md-2 d-flex justify-content-md-center align-items-center">

                        <span class="badge bg-secondary" :style="'position: relative; background-color: ' + $projeto.status_cor(tarefa.status) + '!important;'">
                            {{ tarefa.status.nome | upper }}
                            <span v-if="tarefa.prioridade && tarefa.prioridade.nome" class="position-absolute top-0 start-100 translate-middle p-1 border border-light rounded-circle" :title="tarefa.prioridade.nome" :style="{ 'background-color' : tarefa.prioridade.cor }">
                                <span class="visually-hidden">{{ tarefa.prioridade.nome }}</span>
                            </span>
                        </span>
                    </div>
   
                    <div class="col-md-7 d-flex justify-content-start align-items-center">
                        <span class="titulo">
                            <router-link :to="{ name: 'tarefa', params: { id: tarefa.id, projeto_id: $route.params.projeto_id }}">{{ tarefa.titulo }}</router-link>

                            <br v-if="tarefa.tags" />

                            <span class="tags" v-for="(tag, tag_i) in tarefa.tags" :key="tag_i" :style="{ 'color' : tag.cor } ">
                                <i class="bi bi-tag-fill"></i> {{ tag.nome }}
                            </span>
                                
                        </span>
                    </div>
                    <div class="col-md-3 d-flex justify-content-end align-items-center">

                        <div id="tempo" v-if="tarefa.vencimento">
                            🗓️ {{ tarefa.vencimento | dateParse('YYYY-MM-DD') | dateFormat('DD/MM')  }}
                        </div>

                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                <span class="dados">
                        <code><strong>ID: {{ $route.params.projeto_id }}</strong></code>
                    </span>
                </div>
            </div>

        </s-relatorio>
    </div>
</template>

<script>
export default {

    metaInfo () {
        return {
            title: (this.$store.state.cliente.projeto) ? this.$store.state.cliente.projeto.nome : 'Carregando...'
        }
    },
    data () {
        return {
            tarefas: [],
            tarefas_loading: true
        }
    },

    computed: {

        cliente() {
            return this.$store.state.cliente.dados;
        },
        tarefas_qtd(){
            return this.tarefas.length;
        }

    },

    mounted: async function(){

        
        /** PROJETO */
        /**
        console.log('[PROJETO ID]', this.$route.params.id);
        let projeto = await this.$sdk.get('/projeto/' + this.$route.params.projeto_id);
        //console.log('[PROJETO]', projeto);
        this.projeto = projeto;
        **/

        try{

            /** TAREFAS */
            console.log('[TAREFAS ID]', this.$route.params.id);
            let tarefas = await this.$sdk.get('/tarefas/' + this.$route.params.projeto_id);
            this.tarefas_loading = false;
            //console.log('[PROJETO]', projeto);
            this.tarefas = tarefas;

        }
        catch(e){
            this.$swal.fire({
            title: 'Estamos com dificuldade de acessar essa informação.',
            text: 'Tente novamente em instantes.',
            icon: 'error',
            confirmButtonText: 'Tentar de novo',
            }).then((result) => {
                if (result.isConfirmed) {
                    document.location.reload();
                }
            })

        }
    }

}
</script>