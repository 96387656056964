<template>
    <span v-html="texto">
    </span>
</template>

<script>
export default {
    
    computed: {

        texto(){

            let txt = this.$slots.default[0].text;

            console.log('[CODE]', txt);

            txt = txt.replace(/\[[^\]]+\.(png|jpg|gif)]\(([^\]]+\.(png|jpg|gif))\)/gm, '<img src="$2" class="imagem" />'); //imagem
            txt = txt.replace(/(\*\*)((.)+?)(\*\*)/gm, '<strong>$2</strong>'); //negrito
            txt = txt.replace(/(_)((.)+?)(_)/gm, '<span style="font-style: italic;">$2</span>'); //itálico
            txt = txt.replace(/(^>(.+)?)/gm, '<blockquote>$2</blockquote>'); //citação
            txt = txt.replace(/[`]{1,3}([^`]+)[`]{1,3}/gm, '<code>$1</code>'); //code
            txt = txt.replace(/(#){2}( )(.+)$/gm, '<h2>$3</h2>'); //h2
            txt = txt.replace(/(#){1}( )(.+)$/gm, '<h1>$3</h1>'); //h1
            txt = txt.replace(/(\[[\w.@-_]+\]\(mailto:([\w.@-_]+)\))/mg, '<a href="mailto:$2">$2</a>'); //mailto
            txt = txt.replace(/\[(.+)?\]\((.+)?\)/gm, '<a href="$2" target="_blank">$1</a>'); //links
            txt = txt.replace(/^- (.+)$/gm, '  — $1'); //lista
            txt = txt.replace(/^([0-9]{1,3}). (.+)$/gm, '  $1 — $2'); //lista

            txt = txt.replace(/((^(.)+)\n\n)/gm, '$2<br><br>\n'); //br
            txt = txt.replace(/([-]{3})/gm, '<hr>'); //hr

            return  txt;

        }

    }


}</script>