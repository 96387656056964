<template>
    <component :is="tag" v-html="texto"></component>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            default: 'span'
        }
    },
    
    computed: {

        texto(){

            console.log('slot', this.$slots.default);

            return  this.$slots.default[0].text.replaceAll("\n", '<br />');

        }

    }


}</script>